<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.study_environment.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.study_environment.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <SelectedStudyEnvironmentTimeAllocation
      :object_id="study_environment.id"
      :ViewTimeAllocation="false"
      @update_time_allocation="updateTimeAllocation"
      @remove_time_allocation="removeTimeAllocation"
    ></SelectedStudyEnvironmentTimeAllocation>
    <b-form-group
      v-if="!isNaN(this.study_environment.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="study_environment"></ModificationDate>
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.study_environment.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteStudyEnvironment"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "StudyEnvironmentForm",
  mixins: [validationMixin],
  components: {
    SelectedStudyEnvironmentTimeAllocation: () =>
      import("@/components/reusable/SelectedStudyEnvironmentTimeAllocation"),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    StudyEnvironment: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          updated_by: null,
          update_date: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      study_environment: { ...this.StudyEnvironment },
      tmp_time_allocations: [],
    };
  },
  validations: {
    study_environment: {
      name: {
        required,
        minLength: minLength(5),
      },
      description: {},
    },
  },
  computed: {
    ...mapGetters({
      studyEnvironmentsTimeAllocation: names.STUDY_ENVIRONMENTS_TIME_ALLOCATION,
    }),
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.study_environment[name];
      return $dirty ? !$error : null;
    },
    updateTimeAllocation(time_allocations) {
      if (isNaN(this.study_environment.id)) {
        time_allocations.forEach((time_allocation) => {
          if (!this.tmp_time_allocations.includes(time_allocation))
            this.tmp_time_allocations.push(time_allocation);
        });
      } else if (!isNaN(this.study_environment.id)) {
        this.updateStudyEnvironmentTimeAllocation(time_allocations);
      }
    },
    async updateStudyEnvironmentTimeAllocation(time_allocations) {
      for await (let time_allocation of time_allocations) {
        await this.$store.dispatch(
          names.POST_STUDY_ENVIRONMENT_TIME_ALLOCATION,
          {
            study_environment: this.study_environment.id,
            time_allocation: time_allocation,
          }
        );
      }
    },
    removeTimeAllocation(time_allocation) {
      if (isNaN(this.study_environment.id)) {
        const index = this.tmp_time_allocations.findIndex(
          (x) => x == time_allocation
        );
        if (index != -1) this.tmp_time_allocations.splice(index, 1);
      } else {
        const study_environment_time_allocation =
          this.studyEnvironmentsTimeAllocation.find(
            (x) =>
              x.time_allocation == time_allocation &&
              x.study_environment == this.study_environment.id
          );
        if (study_environment_time_allocation)
          this.$store.dispatch(
            names.DELETE_STUDY_ENVIRONMENT_TIME_ALLOCATION,
            study_environment_time_allocation.id
          );
      }
    },
    save() {
      this.$v.study_environment.$touch();
      if (this.$v.study_environment.$anyError) {
        return;
      }
      if (isNaN(this.study_environment.id)) {
        this.createStudyEnvironment();
      } else this.updateStudyEnvironment();
    },
    createStudyEnvironment() {
      this.$store
        .dispatch(names.POST_STUDY_ENVIRONMENT, this.study_environment)
        .then((response) => {
          this.study_environment.id = response.id;
          if (this.tmp_time_allocations.length > 0)
            this.updateStudyEnvironmentTimeAllocation(
              this.tmp_time_allocations
            );
          toast(
            String(
              this.$getVisibleNames(
                "mesh.studyenvironment",
                false,
                "Ambiente de Aprendizaje"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateStudyEnvironment() {
      this.$store
        .dispatch(names.UPDATE_STUDY_ENVIRONMENT, this.study_environment)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.studyenvironment",
                false,
                "Ambiente de Aprendizaje"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteStudyEnvironment() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.studyenvironment",
          false,
          "Ambiente de Aprendizaje"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_STUDY_ENVIRONMENT,
            this.study_environment.id
          );
          this.$emit("deleted", this.study_environment);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>